import React from 'react'
import "./Stats.css"
import { stats } from '../../constants'
import { Container ,Row } from 'reactstrap'

const Stats = () => {
  return (
    <div>
      <Container className='stat'>
      
            {stats.map((stat)=>(
                <div className='stats'>
                   <div>
                   <p>{stat.value}</p>
                   <h6>{stat.title}</h6>
                  
                   </div>
                    
                </div>
            ))}
        
      </Container>
    </div>
  )
}

export default Stats
