import React from 'react'
import { Container, Row ,Col} from 'reactstrap'
import Card from "../../assets/card.png"
import Button from "../Button"
import './Cta.css'

const Cta = () => {
  return (
    <Container style={{marginTop:"50px"}}>
      <Row>
        <Col lg={6}>
          <div className="card_content">
            <h4>Find a better card deal in few easy steps.</h4>
            <p>Arcu tortor, purus in mattis at sed integer faucibus. Aliquet quis aliquet eget mauris tortor.ç Aliquet ultrices ac, ametau.</p>
            <Button  />
          </div>
        </Col>
        <Col lg={6}>
        <div className='card_img'>
                <img src={Card} alt="" />
            </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Cta
