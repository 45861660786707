import React from 'react'
import { Container ,Row , Col} from 'reactstrap'
import heroImg from "../../assets/robot.png"
import svg from "../../assets/Discount.svg"
import "./Hero.css"

const Hero = () => {
  return (
    <div>
      <Container className='section'>
        <Row>
            <Col lg={6} className="text-white hero__left">
                <div className="get_started_btn">Get <br/>started<i class="ri-arrow-right-up-line"></i></div>
                <p> <img src={svg} alt="" />20% Discount for <span>1 Month Account</span> </p>
                <h2>The Next<br/><span>Generation</span><br/>Payment Method</h2>
                <p>Our team of experts uses a methodology to identify the credit cards most likely to fit your needs. 
                We examine annual percentage rates, annual fees.</p>
               
            </Col>
            <Col lg={6} className="hero__right">
                <div className='shadow'></div>
                <img src={heroImg} alt="" />
            </Col>
        </Row>
      </Container>
     
    </div>
  )
}

export default Hero
