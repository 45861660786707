import React from 'react'
import { Container, Row,Col } from 'reactstrap'
import './Testimonial.css'
import { feedback} from "../../constants/index"
import quotes  from '../../assets/quotes.svg'

const Testimonial = () => {
  return (
    <>
    <Container className='section'>
      <Row>
        <div className='test_header'>
            <h4>What people are saying about us</h4>
            <p>Everything you need to accept card payments and grow your business anywhere on the planet.</p>
        </div>
      </Row>
    </Container>

    <Container>
        <Row>
          {feedback.map((card)=>(
            <Col lg={4}>
                <div className='Card' key={card.id}>
                    <img src={quotes} alt="" />
                    <div className="card_content">
                        <p>{card.content}</p>
                    </div>
                    <div className="card_profile">
                        <img src={card.img} alt="" />
                        <div className="card_profile_data">
                            <h4>{card.name}</h4>
                            <p>{card.title}</p>
                        </div>
                    </div>
                </div>
            </Col>
          ))}
        </Row>
    </Container>
    
    </>
  )
}

export default Testimonial
