import React from 'react'
import { Container ,Row ,Col } from 'reactstrap'
import Button from '../Button'
import "./Feature.css"
import {features} from "../../constants/index"

const Feature = () => {
  return (
    <Container className='section'>
      <Row>
        <Col lg={6}>
           <div className="f_left">
           <h3>You do the business <br/> we'll handle the money</h3>
            <p>With the right credit card, you can improve your financial life by building credit, earning rewards and saving money. But with hundreds of credit cards on the market.</p>
            <Button/>
            
           </div>
        </Col>
        <Col lg={6}>
            {features.map((feature ,id)=>(
                 <>
                 <div className="f_right" key={feature.id}>
                 <div className='img'>
                    {feature.icon}
                   </div> 
                   <div className="content">
                    <h4>{feature.title}</h4>
                    <p>{feature.content}</p>
                   </div>
                 </div>
                
                 </>

            ))}
        </Col>
      </Row>
    </Container>
  )
}

export default Feature
