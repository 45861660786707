import React from 'react'
import { Container ,Row, Col } from 'reactstrap'
import {footerLinks } from "../../constants/index"
import { logo } from '../../assets'
import "./Footer.css"
import {socialMedia} from "../../constants/index"

const Footer = () => {
  return (
  <>
    <Container className='section footer'style={{marginTop:"40px"}}  >
      <Row>
       <Col lg={3}>
        <div className='logo'>
            <img src={logo} alt="" />
            <p>A new way to make the payments easy, reliable and secure.</p>
        </div>
       </Col>
       {footerLinks.map((link)=>(
        <Col lg={3} sm={6} className="footer_links">
            <div className="title">{link.title}</div>
            {link.links.map((useFulLink)=>(
                <ul>
                    <li>{useFulLink.name}</li>
                    
                </ul>

            ))}
        </Col>
       ))}
      </Row>
    </Container>

    <Container >
     <Row>
      <Col lg={6} className="footer_copyright">
      <div>
        <p>Copyright &copy; 2021 Mr Siraj. All Rights Reserved.</p>
      </div>
      </Col>
      <Col lg={6} className="footer_icons">
      {socialMedia.map((social)=>(
         <div key={social.id} >
           <img src={social.icon} alt="" />
         </div>
            ))}
      </Col>
     </Row>
      
     
     
   
    
    </Container>
  </>
  )
}

export default Footer
