import Billing from "./components/Billing/Billing";
import Brand from "./components/Brands/Brand";
import Cta from "./components/CTA/Cta";
import Feature from "./components/Features/Feature";
import Footer from "./components/Footer/Footer";
import Hero from "./components/Hero/Hero";
import Navbar from "./components/Navbar/Navbar";
import Stats from "./components/Stats/Stats";
import Testimonial from "./components/Testimonial/Testimonial";


function App() {
  return (
    <div className="App">
   <Navbar/>
   <Hero/>
   <Stats/>
   <Feature/>
   <Billing/>
   <Cta/>
   <Testimonial/>
   <Brand/>
   <Footer/>
    </div>
  );
}

export default App;
