import React from 'react'
import { Container ,Row , Col } from 'reactstrap'
import Apple from "../../assets/apple.svg"
import Google from "../../assets/google.svg"
import "./Billing.css"
import Bill from "../../assets/bill.png"

const Billing = () => {
  return (
    <Container className='section'>
      <Row>
        <Col lg={6}>
        <div className='billing_img'>
        <img src={Bill} alt="" />
        </div>
        </Col>
        <Col lg={6}>
            <div className='billing_content'>
              {/* <div className='shadow'></div> */}
                <h4>Easily control your billing & invoicing.</h4>
                <p>Elit enim sed massa etiam. Mauris eu adipiscing ultrices ametodio aenean neque. Fusce ipsum orci rhoncus aliporttitor integer platea placerat.</p>
            </div>
            <div className="buttons">
                <img src={Apple} alt="" />
                <img src={Google} alt="" />
            </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Billing
