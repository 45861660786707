import React from 'react'
import "./button.css"

const button = () => {
  return (
    <div>
      <button style={{marginTop:"30px"}} >Get Started</button>
    </div>
  )
}

export default button
