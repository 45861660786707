import React, { useState } from 'react'
import logo from "../../assets/logo.svg"
import { Container  } from 'reactstrap'
import {navLinks } from "../../constants/index"
import "./Navbar.css"

const Navbar = () => {
    const mobile = window.innerWidth <= 768 ? true : false;
    const [openMenu , setopenMenu] = useState(false);
  return (
   <Container>
     <div className='Navbar'>
      <div className="nav__left">
        <img src={logo} alt="" />
      
        
      </div>

      {openMenu === false && mobile == true ?
      
    (  <div onClick={(()=>setopenMenu(true))}>
    <i className="ri-bar-chart-horizontal-line icon"></i>
  </div>)

      :(

      <div className="nav__right">
        {navLinks.map((link)=>(
                <ul key={link.id} className="nav__links">
                    <li onClick={(()=>setopenMenu(false))}>{link.title}</li>
                </ul>
               
        ))}
      </div>
      )}
    </div>
   </Container>
  )
}

export default Navbar
