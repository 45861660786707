import React from 'react'
import { Container ,Row} from 'reactstrap'
import {clients} from '../../constants/index'
import "./Brand.css"
import Button from '../Button'

const Brand = () => {
  return (
    <div>
      <Container className='section brand' style={{display:"flex" , alignItems:"center" ,justifyContent
    :"space-between"
    }}>
     
          
                {clients.map((client)=>(
                    <div className='brands' key={client.id}>
                        <img src={client.logo} alt="" />
                    </div>
                    ))}
            
      
      </Container>

      <Container className='newsLetter'>
        <div className='newsLetter_left'>
            <h4>Let’s try our service now!</h4>
            <p>Everything you need to accept card payments and grow your business anywhere on the planet.</p>
        </div>
        <div className='newsLetter_right'>
                    <Button/>
        </div>
      </Container>
    </div>
  )
}

export default Brand
